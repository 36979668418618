import { Container } from '@mui/material';

import DrawerAppBar from "./Components/DrawerAppBar";

function Header() {
    return (
        <>
            <header className="header">
                <Container sx={{ bgcolor: "background.main" }}>
                    <DrawerAppBar />
                </Container>
            </header>
        </>
    )
}

export default Header;