import { useLocation } from "react-router-dom";

import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';

import BasicBreadcrumbs from "../Components/BasicBreadcrumbs";
import SearchResultRow from '../Components/SearchResultRow';

function Search(props) {
    const breadcrumbs = [{name:"Search Results", link:""}];
    const location = useLocation();

    if(location.state === null) {
        return(
            <Container maxWidth="md" sx={{ minHeight: 50, pb: 2 }}>
                <BasicBreadcrumbs crumbs={breadcrumbs} />
                <Box>
                    No results found.
                </Box>
            </Container>
        );
    } else {
        const hasCategories = location.state['categories'] != null && location.state['categories'].length > 0 ? true : false;
        const hasDesigns = location.state['designs'] != null && location.state['designs'].length > 0 ? true : false;
        const noResults = !hasCategories && !hasDesigns;

        return(
            <Container maxWidth="md" sx={{ minHeight: 700, pb: 2 }}>
                <BasicBreadcrumbs crumbs={breadcrumbs} />
                <Box>
                    {hasCategories && location.state['categories'].map((item) => (
                        <SearchResultRow description={item.description} key={item.id} link={item.search_link} title={item.name} />
                    ))}
                    {hasDesigns && location.state['designs'].map((item) => (
                        <SearchResultRow description={item.short_description} key={item.id} link={item.search_link} title={item.title} />
                    ))}
                    {noResults && <Typography sx={{pb:10, textAlign:"center"}}>No results found.</Typography>}
                </Box>
            </Container>
        );
    }
}

export default Search;