import * as React from 'react';
import { Link } from "react-router-dom";

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

function CategoryIconItem(props) {
    const imgUrl = "/img/" + props.details.image;

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
            <picture>
                <Link to={"/category/" + props.details.url_key}>
                    <img className="category-icon" src={imgUrl} alt={props.details.name} />
                </Link>
            </picture>
            <Button component={Link} size="small" sx={{ml:'auto', mt:0, pt:0}} to={"/category/" + props.details.url_key}>
                {props.details.short_name}
            </Button>
        </Stack>
    );
}

export default CategoryIconItem;