import React from 'react';
import { Link } from "react-router-dom";

import SearchIcon from '@mui/icons-material/Search';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Logo from "./Logo";
import SearchDrawer from "./SearchDrawer";

const drawerWidth = 240;
const navItems = [
  { id: "homenav", name: "Home", link: "/" },
  { id: "designsnav", name: "Designs", link: "/designs" },
  { id: "servicesnav", name: "Services", link: "/services" },
  { id: "contactnav", name: "Contact", link: "/contact" }
];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [searchState, setSearchState] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const toggleSearchDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setSearchState(open);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Logo type="black-text" />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} component={Link} to={item.link}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar component="nav">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { sm: 'block' }, textAlign: { xs:"center", sm:"left" } }}
            >
              <Link to="/"><Logo type="mobile-white" /></Link>
            </Typography>
            <IconButton
              color="inherit"
              aria-label="search"
              edge="end"
              onClick={toggleSearchDrawer(!searchState)}
              sx={{ ml: 2, display: { sm: 'none' } }}
            >
              <SearchIcon />
            </IconButton>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {navItems.map((item) => (
                <Button key={item.id} sx={{ color: '#fff' }} component={Link} to={item.link}>
                  {item.name}
                </Button>
              ))}
              <IconButton
                color="inherit"
                aria-label="search"
                edge="end"
                onClick={toggleSearchDrawer(!searchState)}
                sx={{ ml: 2 }}
              >
                <SearchIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>

      <SearchDrawer searchState={searchState} setSearchState={setSearchState} />
    </>
  );
}

export default DrawerAppBar;