import { Link } from "react-router-dom";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Container } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

import CategoryList from "../Components/CategoryList";
import FeatureIcons from "../Components/FeatureIcons";
import FeatureMain from "../Components/FeatureMain";
import Hero from "../Components/Hero";
import MetaSEO from "../Components/MetaSEO";
import StoreLinks from "../Components/StoreLinks";

function Home() {
    const docTitle = "Pug Dogz Designs";
    const docOGUrl = "http://www.pugdogzdesigns.com";

    return (
        <>
            <MetaSEO docTitle={docTitle} docOGUrl={docOGUrl} />
            <Hero />
            <Container disableGutters maxWidth="md">
                <FeatureIcons />
                <Box sx={{mb: { xs: 4 }, ml: { xs: 6, sm: 2, md: 0 }, mr: { xs: 6, sm: 2, md: 0 }, mt: { xs: 1 }, p:4}}>
                    <FeatureMain />
                </Box>

                <Box className="browse-categories" sx={{ bgcolor: {xs: "secondary.main", sm: "background.main"}, ml: { xs: 0, sm: 2 }, mr: { xs: 0, sm: 2 }, pb:3, pt:3} }>
                    <Typography sx={{mb: {xs: 0, sm: 4}, textAlign:"center"}} variant="h2">
                        Browse our categories:
                    </Typography>
                    <CategoryList />
                </Box>

                <Box sx={{ mt:2 }}>
                    <Typography sx={{textAlign:"center"}} variant="h2">
                        Shop our stores:
                    </Typography>
                    <StoreLinks />
                </Box>

                <Box className="secondary-panel" sx={{ p:2, mt: 0 }}>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                        <Button component={Link} size="small" sx={{ml:'auto'}} to={"/designs/all"}>
                            Browse All Designs <ArrowRightAltIcon />
                        </Button>
                    </Stack>
                </Box>
            </Container>
        </>
    );
}

export default Home;