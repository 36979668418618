import HomeIcon from '@mui/icons-material/Home';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

function BreadcrumbItem(props) {
    let itemDisplay = "";

    if (props.name === 'Home') {
        itemDisplay = <HomeIcon sx={{ mr: 0.5, mt: 0.5 }} fontSize="inherit" />;
    } else {
        itemDisplay = props.name;
    }

    if (props.link === '') {
        return <Typography color="text.primary">{itemDisplay}</Typography>;
    } else {
        return (
            <Link underline="hover" color="inherit" href={props.link}>
                {itemDisplay}
            </Link>
        );
    }
}

export default BreadcrumbItem;