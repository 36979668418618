import React, { useCallback, useRef } from "react";

import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

import BasicBreadcrumbs from "../Components/BasicBreadcrumbs";
import DesignsImage from "../Components/DesignsImage";
import MetaSEO from "../Components/MetaSEO";

function DesignsAll() {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [json, setJson] = React.useState([]);
    const docTitle = "All Designs - Pug Dogz Designs";
    const docOGUrl = "http://www.pugdogzdesigns.com/designs/all";
    const breadcrumbs = useRef([]);

    const fetchData = useCallback(() => {
        fetch("https://api.pugdogzdesigns.com/designs/all")
            .then((response) => response.json())
            .then((result) => { 
                setIsLoaded(true); 
                setJson(result);
                updateBreadcrumbs(result);
             })
            .catch((error) => { 
                setIsLoaded(true); 
                setError(error); });
    }, []);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    const updateBreadcrumbs = function (result) {
        breadcrumbs.current = [...breadcrumbs.current, { name: 'Designs', link: '/designs' }];
        breadcrumbs.current = [...breadcrumbs.current, { name: 'All Designs', link: '' }];
    };

    if (error) {
        return <div className="hide">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return (
            <Container maxWidth="md" sx={{ pb: 2 }}>
                <Grid spacing={0} container>
                    <Grid xs item>
                        <LinearProgress />
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="md" sx={{ pb: 2 }}>
                <MetaSEO docTitle={docTitle} docOGUrl={docOGUrl} />
                <BasicBreadcrumbs crumbs={breadcrumbs.current} />
                <Box className="mobile-full" sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} alignItems="stretch">
                        {Object.keys(json.designs).map(key => (
                            <Grid key={json.designs[key].id} item md={6}>
                                <DesignsImage key={key} details={json.designs[key]} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        );
    }
}

export default DesignsAll;