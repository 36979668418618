import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { Typography } from '@mui/material';

function Hero() {
    return (
        <Box sx={{ bgcolor: "background.hero" }}>
            <Container maxWidth="md">
                <Box className="hero">
                    <Box className="hero-bg"></Box>
                    <Box className="hero-content" sx={{ ml: 'auto', mr: 'auto', pb: { xs: 6, sm: 15 }, pl: { xs: 5, sm: 15 }, pr: { xs: 5, sm: 15 }, pt: { xs: 6, sm: 15, textAlign: "center" } }}>
                        <Typography sx={{ mb: 2 }} variant="h1">
                            Custom T-shirt Design
                        </Typography>
                        <Typography>
                            Unleash the extraordinary with our curated T-shirt designs, where creativity meets comfort in every thread.
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export default Hero;