import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import CreateHtml from './CreateHtml';

function FeatureMainItem(props) {
    const imgUrl = "/img/" + props.details.image;

    return (
        <>
        <Typography sx={{ mb: 2, mt: { xs: 4 }, textAlign:"center" }} variant="h3">
            Featured Design
        </Typography>
        <Box className="feature-main-item">
            <Box className="feature-main-image" sx={{p:2, textAlign:"center"}}>
                <img alt={props.details.title} src={imgUrl} />
            </Box>
            <Box sx={{p:2}}>
                <Typography color="text.secondary" variant="h3" gutterBottom>
                    {props.details.title}
                </Typography>
                <Typography variant="body2">
                    <CreateHtml htmlString={props.details.description} />
                </Typography>
            </Box>
            <Box sx={{p:2, textAlign:"center"}}>
                <Button variant="contained" href={props.details.link} sx={{ m:'auto' }} target="_blank">
                    Buy Now
                </Button>
            </Box>
        </Box>
        </>
    );
}

export default FeatureMainItem;