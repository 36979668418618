import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

function StoreLinks() {
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ pb: 1, pl: 1, pr: 1, pt: 1 }}>
                    <Grid item xs={1}>
                        <picture><img alt="Teespring" src="/img/teespring_logo.png" /></picture>
                    </Grid>
                    <Grid item xs={9} sx={{p:2, lineHeight:1}}>
                        <Typography variant="h3">
                            Teespring
                        </Typography>
                        <Typography variant="caption">
                        T-shirts, hoodies, tote bags, aprons, stickers, mugs, iphone cases...
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{textAlign:'right'}}>
                            <Link href="https://my-store-f0fda2.creator-spring.com" target="_blank"><OpenInNewIcon /></Link>
                        </Box>
                    </Grid>
                </Grid>
                <Divider light />
                
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ pb: 1, pl: 1, pr: 1, pt: 1 }}>
                    <Grid item xs={1}>
                        <picture><img alt="Redbubble" src="/img/redbubble_logo.png" /></picture>
                    </Grid>
                    <Grid item xs={9} sx={{p:2, lineHeight:1}}>
                        <Typography variant="h3">
                            Redbubble
                        </Typography>
                        <Typography variant="caption">
                            T-shirts, hoodies, tote bags, aprons, stickers, mugs...
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{textAlign:'right'}}>
                            <Link href="https://www.redbubble.com/people/pugdogzgdesigns/shop" target="_blank"><OpenInNewIcon /></Link>
                        </Box>
                    </Grid>
                </Grid>
                <Divider light />

                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ pb: 1, pl: 1, pr: 1, pt: 1 }}>
                    <Grid item xs={1}>
                        <picture><img alt="Threadless" src="/img/threadless_logo.png" /></picture>
                    </Grid>
                    <Grid item xs={9} sx={{p:2, lineHeight:1}}>
                        <Typography variant="h3">
                            Threadless
                        </Typography>
                        <Typography variant="caption">
                            T-shirts, hoodies, sweatshirts...
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{textAlign:'right'}}>
                            <Link href="https://pugdogz.threadless.com" target="_blank"><OpenInNewIcon /></Link>
                        </Box>
                    </Grid>
                </Grid>
                <Divider light />

                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ pb: 1, pl: 1, pr: 1, pt: 1 }}>
                    <Grid item xs={1}>
                        <picture><img alt="Spreadshirt" src="/img/spreadshirt_logo.png" /></picture>
                    </Grid>
                    <Grid item xs={9} sx={{p:2, lineHeight:1}}>
                        <Typography variant="h3">
                            Spreadshirt
                        </Typography>
                        <Typography variant="caption">
                            T-shirts, hoodies, tote bags, aprons, stickers, mugs...
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{textAlign:'right'}}>
                            <Link href="https://pug-dogz.myspreadshop.com" target="_blank"><OpenInNewIcon /></Link>
                        </Box>
                    </Grid>
                </Grid>
                <Divider light />

                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ pb: 1, pl: 1, pr: 1, pt: 1 }}>
                    <Grid item xs={1}>
                        <picture><img alt="Amazon" src="/img/amazon_logo.png" /></picture>
                    </Grid>
                    <Grid item xs={9} sx={{p:2, lineHeight:1}}>
                        <Typography variant="h3">
                            Amazon
                        </Typography>
                        <Typography variant="caption">
                            T-shirts...
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{textAlign:'right'}}>
                            <Link href="https://www.amazon.com/s?rh=n%3A7141123011%2Cp_4%3APug+Dogz" target="_blank"><OpenInNewIcon /></Link>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default StoreLinks;