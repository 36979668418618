function MetaSEO(props) {
    if(props.docTitle) {
        document.title = props.docTitle;
        document.querySelector('meta[property="og:title"]').setAttribute("content", props.docTitle);
    }

    if(props.docDescription) {
        document.querySelector('meta[name="description"]').setAttribute("content", props.docDescription);
    }

    if(props.docOGDescription) {
        document.querySelector('meta[property="og:description"]').setAttribute("content", props.docOGDescription);
    }

    if(props.docOGImage) {
        document.querySelector('meta[property="og:image"]').setAttribute("content", props.docOGImage);
    }

    if(props.docOGUrl) {
        document.querySelector('meta[property="og:url"]').setAttribute("content", props.docOGUrl);
    }
    
    return (<></>);
}

export default MetaSEO;