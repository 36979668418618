import { Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function SearchResultRow(props) {
    return(
        <Box sx={{ mb: 3 }}>
            <Typography variant="h6">
                <Link to={props.link}>{props.title}</Link>
            </Typography>
            <Typography>
                {props.description}
            </Typography>
        </Box>
    );
};

export default SearchResultRow;