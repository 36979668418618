import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';

import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

function Footer() {
    const today = new Date();
    
    return (
        <footer>
            <Container maxWidth="md" sx={{ pb: 2, pt: 2 }}>
                <Box sx={{ flexGrow: 1 }}>
                        <Grid
                            alignItems="center" 
                            container 
                            direction="row"
                            justifyContent="center"
                            spacing={2}
                        >
                        <Grid item>
                            <Link href="https://www.instagram.com/pug_dogz" rel="noopener" target="_blank"><InstagramIcon /></Link>
                        </Grid>
                        <Grid item>
                            <Link href="https://www.facebook.com/pugdogzdesigns" rel="noopener" target="_blank"><FacebookIcon /></Link>
                        </Grid>
                        <Grid item>
                            <Link href="https://www.pinterest.com/pugdogzdesigns" rel="noopener" target="_blank"><PinterestIcon /></Link>
                        </Grid>
                    </Grid>
                </Box>
                &copy; {today.getFullYear()} Pug Dogz Designs
            </Container>
        </footer>
    )
}

export default Footer;