import React, { useCallback, useRef } from "react";
import { useParams } from "react-router-dom";

import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

import BasicBreadcrumbs from "../Components/BasicBreadcrumbs";
import DesignItem from "../Components/DesignItem";

function DesignDetails() {
    const { designKey } = useParams();
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [json, setJson] = React.useState([]);
    const breadcrumbs = useRef([]);

    const fetchData = useCallback(() => {
        fetch(`https://api.pugdogzdesigns.com/designs/byDesign?designKey=${designKey}`)
            .then((response) => response.json())
            .then((result) => {
                setIsLoaded(true);
                setJson(result);
                updateBreadcrumbs(result);
            })
            .catch((error) => { 
                setIsLoaded(true); 
                setError(error); });
    }, [designKey]);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    const updateBreadcrumbs = function (result) {
        breadcrumbs.current = [...breadcrumbs.current, { name: 'Designs', link: '/designs' }];
        breadcrumbs.current = [...breadcrumbs.current, { name: result.category[0].name, link: '/category/' + result.category[0].url_key }];
        breadcrumbs.current = [...breadcrumbs.current, { name: result.design[0].title, link: '' }];
    };

    if (error) {
        return <div className="hide">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return (
            <Container maxWidth="md" sx={{ pb: 2 }}>
                <Grid spacing={0} container>
                    <Grid xs item>
                        <LinearProgress />
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="md" sx={{ pb: 2 }}>
                <BasicBreadcrumbs crumbs={breadcrumbs.current} />
                {Object.keys(json.design).map(key => (
                    <DesignItem key={key} details={json.design[key]} isDetail={true} />
                ))}
            </Container>
        );
    }
}

export default DesignDetails;