import React, { useCallback } from "react";

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import CategoryIconItem from "./CategoryIconItem";
import CategoryItem from "./CategoryItem";

function CategoryList() {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [json, setJson] = React.useState([]);

    const fetchData = useCallback(() => {
        fetch("https://api.pugdogzdesigns.com/categories")
            .then((response) => response.json())
            .then((result) => { setIsLoaded(true); setJson(result); })
            .catch((error) => { setIsLoaded(true); setError(error); });
    }, []);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    const CategoryPaper = styled(Paper)(({ theme }) => ({
        padding: "0px",
    }));

    if (error) {
        return <div className="hide">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return (
            <Grid spacing={0} container>
                <Grid xs item>
                    <LinearProgress />
                </Grid>
            </Grid>
        );
    } else {
        return (
            <>
                <Box display={{ xs: 'block', sm: 'none' }} sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} alignItems="stretch">
                        {Object.keys(json).map(key => (
                            <Grid key={key} item xs={4}>
                                <CategoryIconItem key={key} details={json[key]} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box display={{ xs: 'none', sm: 'block' }}>
                    <CategoryPaper>
                        {Object.keys(json).map(key => (
                            <div key={key}>
                                <CategoryItem key={key} details={json[key]} />
                                <Divider light />
                            </div>
                        ))}
                    </CategoryPaper>
                </Box>
            </>
        );
    }
}

export default CategoryList;