import { useState } from "react";
import axios from 'axios';

const useContactSubmit = () => {
    const [response, setResponse] = useState(null);

    const submit = async (url, data) => {
        try {
            axios.post("https://api.pugdogzdesigns.com/contact/sendMessage", { data })
                .then(res => {
                    if (res.data.success === true) {
                        setResponse({
                            type: 'success',
                            alertTitle: 'Thank you!',
                            alertMessage: 'Your message has been submitted.'
                        });
                    } else {
                        setResponse({
                            type: 'error',
                            alertTitle: 'Error!',
                            alertMessage: 'An error has occurred. Please try again.'
                        });
                    }
                })
                .catch(res => {
                    setResponse({
                        type: 'error',
                        alertTitle: 'Error!',
                        alertMessage: 'An error has occurred. Please try again.'
                    });
                });
        } catch (error) {
            setResponse({
                type: 'error',
                alertTitle: 'Error!',
                alertMessage: 'An error has occurred. Please try again.'
            });
        }
    };

    return { response, submit };
}

export default useContactSubmit;