import { Routes, Route } from "react-router-dom";

import Box from '@mui/material/Box';

import DesignDetails from "./Pages/DesignDetails";
import DesignsAll from "./Pages/DesignsAll";
import DesignsList from "./Pages/DesignsList";
import Contact from "./Views/Contact";
import Designs from "./Views/Designs";
import Home from "./Views/Home";
import Search from "./Views/Search";
import Services from "./Views/Services";

function Main(props) {
    return (
        <main>
            <Box sx={{ bgcolor: "background.main" }}>
                <Routes> 
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/designs" element={<Designs />}></Route>
                    <Route path="/services" element={<Services />}></Route>
                    <Route path="/designs/all" element={<DesignsAll />}></Route>
                    <Route path="/contact" element={<Contact />}></Route>
                    <Route path="/category/:categoryKey" element={<DesignsList />}></Route>
                    <Route path="/design/:designKey" element={<DesignDetails />}></Route>
                    <Route path="/search" element={<Search />}></Route>
                    <Route path="" element={<Home />}></Route>
                </Routes>
            </Box>
        </main>
    )
}

export default Main;