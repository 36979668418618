import * as React from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';

import BreadcrumbItem from './BreadcrumbItem';

function BasicBreadcrumbs(props) {
  let jsonObject = props.crumbs.map(JSON.stringify);
  let uniqueSet = new Set(jsonObject);
  let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

  return (
    <Grid container justifyContent="center" sx={{pl: { xs: 2, sm: 0 }, pr: { xs: 2, sm: 0 }, pt: 2, mt: 0, mb: {xs: 2, sm: 3}}}>
      <Breadcrumbs aria-label="breadcrumb">
        <BreadcrumbItem link="/" name="Home" />
        {Object.keys(uniqueArray).map(key => (
          <BreadcrumbItem key={key} link={uniqueArray[key].link} name={uniqueArray[key].name} />
        ))}
      </Breadcrumbs>
    </Grid>
  );
}

export default BasicBreadcrumbs;