import React from 'react';
import { useNavigate } from "react-router-dom";

import SearchIcon from '@mui/icons-material/Search';

import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

function SearchDrawer(props) {
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = React.useState(false);
    const [kwState, setKwState] = React.useState("");
    const isValid = React.useRef(false);
    const wrapperRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                if(event.target.getAttribute('data-testid') != null && event.target.getAttribute('data-testid') === 'SearchIcon') {
                    
                } else if(event.target.parentNode.getAttribute('data-testid') != null && event.target.parentNode.getAttribute('data-testid') === 'SearchIcon') {
                    
                } else {
                    props.setSearchState(false);
                    isValid.current = false;
                    setKwState("");
                }
            }
        }
        
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, props]);

    const handleChange = e => {
        const value = e.target.value;
        setKwState(value);
    }

    React.useEffect(() => {
        if(kwState.length < 3) {
            isValid.current = false;
        } else {
            isValid.current = true;
        }
    });
    

    function handleClick() {
        setShowLoader(true);

        fetch("https://api.pugdogzdesigns.com/search/byKeyword?kw=" + kwState)
            .then((response) => response.json())
            .then((result) => {
                props.setSearchState(false);
                setShowLoader(false);
                isValid.current = false;
                setKwState("");
                navigate('/search', { state: result });
            })
            .catch((error) => { 
                isValid.current = false;
                setKwState("");
                navigate('/search', { state: null });
            });
    };

    return (
        <>
            <Drawer
                anchor="top"
                open={props.searchState}
                sx={{ zIndex: 1000 }}
            >
                <Stack
                    alignItems="center"
                    direction="column"
                    justifyContent="center"
                    ref={wrapperRef}
                    spacing={2}
                    sx={{ bgcolor:"secondary.main", pb: 0, pl: 4, pr: 4, pt: 10 }}
                >
                    <Paper sx={{ bgcolor:"#fff", pb:1, pl:3, pr:3, pt:1 }}>
                        <InputBase
                            id="kw"
                            inputProps={{ 'aria-label': 'search' }}
                            name="kw"
                            sx={{ flex: 1 }}
                            onChange={handleChange} 
                            placeholder="Search"
                        />
                        <IconButton disabled={!isValid.current} onClick={handleClick} type="button" sx={{ bgcolor:"secondary.main", p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                    <Grid spacing={0} container>
                        <Grid xs item>
                            {showLoader && <LinearProgress />}
                        </Grid>
                    </Grid>
                </Stack>
            </Drawer>
        </>
    );
}

export default SearchDrawer;