import React, { useCallback } from "react";

import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

import FeatureMainItem from "./FeatureMainItem";


function FeatureMain() {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [json, setJson] = React.useState([]);

    const fetchData = useCallback(() => {
        fetch('https://api.pugdogzdesigns.com/features/home')
            .then((response) => response.json())
            .then((result) => {
                setIsLoaded(true);
                setJson(result);
            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error);
            });
    }, []);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (error) {
        return <div className="hide">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return (
            <Grid spacing={0} container>
                <Grid xs item>
                    <LinearProgress />
                </Grid>
            </Grid>
        );
    } else {
        return (
            <>
                {Object.keys(json.main).map(key => (
                    <FeatureMainItem key={key} details={json.main[key]} />
                ))}
            </>
        );
    }
}

export default FeatureMain;