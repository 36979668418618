import DeveloperBoardOffIcon from '@mui/icons-material/DeveloperBoardOff';
import GestureIcon from '@mui/icons-material/Gesture';
import PaletteIcon from '@mui/icons-material/Palette';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

function FeatureIcons() {
    return (

        <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{mt: 3}}
        >
            <Box sx={{textAlign:"center"}}>
                <PaletteIcon color="secondary" fontSize="large" />
                <Box>
                    <Typography variant="caption">
                        Original artwork
                    </Typography>
                </Box>
            </Box>
            <Box sx={{textAlign:"center"}}>
                <GestureIcon color="secondary" fontSize="large" />
                <Box>
                    <Typography variant="caption">
                        Hand-drawn
                    </Typography>
                </Box>
            </Box>
            <Box sx={{textAlign:"center"}}>
                <DeveloperBoardOffIcon color="secondary" fontSize="large" />
                <Box>
                    <Typography variant="caption">
                        Made by humans
                    </Typography>
                </Box>
            </Box>
        </Stack>
    );
}

export default FeatureIcons;