import { createTheme, ThemeProvider } from '@mui/material/styles';

import BackToTop from './Components/BackToTop';
import Footer from "./Footer";
import Header from "./Header";
import Main from "./Main";

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#37474f',
    },
    secondary: {
      main: '#b2ebf2',
    },
    background: {
      hero: '#000000',
      footer: '#e3f2fd',
      main: '#ffffff'
    }
  },
  typography: {
    h1: {
      fontSize: '1.5rem',
      fontWeight: 500
    },
    h2: {
      fontSize: '1.2rem',
      fontWeight: 500
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 500
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 500
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 500
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500
    },
  }
});

function App() {
  return (
      <ThemeProvider theme={theme}>
        <Header />
        <Main />
        <Footer />
        <BackToTop />
      </ThemeProvider>
  );
}

export default App;
