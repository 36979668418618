import { Container } from '@mui/material';

import BasicBreadcrumbs from "../Components/BasicBreadcrumbs";
import CategoryList from "../Components/CategoryList";
import MetaSEO from "../Components/MetaSEO";

function Designs() {
    const docTitle = "Designs - Pug Dogz Designs";
    const docOGUrl = "http://www.pugdogzdesigns.com/designs";
    const breadcrumbs = [{name:"Designs", link:""}];

    return (
        <Container maxWidth="md" sx={{ pb: 2 }}>
            <MetaSEO docTitle={docTitle} docOGUrl={docOGUrl} />
            <BasicBreadcrumbs crumbs={breadcrumbs} />
            <CategoryList />
        </Container>
    );
}

export default Designs;