import React from 'react';
import { Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CreateHtml from './CreateHtml';
import MetaSEO from "./MetaSEO";

function DesignItem(props) {
  const imgUrl = "/img/" + props.details.image;
  let detailsButton = undefined;
  let mediaImage = undefined;
  let metaSEO = undefined;

  if (!props.isDetail) {
    detailsButton = <Button component={Link} key={props.details.id} size="small" to={"/design/" + props.details.url_key}>More details</Button>;
    mediaImage = <CardMedia
      sx={{ height: 200 }}
      image={imgUrl}
      title={props.details.title}
    />;
  } else {
    const docTitle = props.details.title + " - Pug Dogz Designs";
    const docOGUrl = `http://www.pugdogzdesigns.com/design/${props.details.url_key}`;
    metaSEO = <MetaSEO docTitle={docTitle} docOGUrl={docOGUrl} />;
    mediaImage = <figure className="details-image"><img alt={props.details.title} src={imgUrl} /></figure>
  }

  const card = (
    <React.Fragment>
      {mediaImage}
      <CardContent>
        <Typography color="text.secondary" gutterBottom variant="h2">
          {props.details.title}
        </Typography>
        <Typography variant="body2">
          <CreateHtml htmlString={props.isDetail ? props.details.description : props.details.short_description} />
        </Typography>
      </CardContent>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        sx={{p:2}}
      >
        {detailsButton}
        <Button variant="contained" href={props.details.link} sx={{ m: 1 }} target="_blank">
          Buy Now
        </Button>
      </Stack>
    </React.Fragment>
  );

  return (
    <>
      {metaSEO}
      <Box>
        <Card variant="outlined">{card}</Card>
      </Box>
    </>
  );
}

export default DesignItem;