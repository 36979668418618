import * as React from 'react';
import { Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

function CategoryItem(props) {
  const imgUrl = "/img/" + props.details.image;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item md={3}>
          <figure className="category-image"><img className="category-icon" alt={props.details.name} src={imgUrl} /></figure>
        </Grid>
        <Grid item md={9}>
          <div className="category-list-content">
            <Typography color="text.secondary" gutterBottom variant="h2">
              {props.details.name}
            </Typography>
            <Typography variant="body2" sx={{ mb: 3 }}>
              {props.details.description}
            </Typography>
            <Button component={Link} key={props.details.id} size="small" to={"/category/" + props.details.url_key} variant="contained">Browse</Button>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CategoryItem;