import React from "react";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import Fab from '@mui/material/Fab';
import { useScrollTrigger, Zoom } from '@mui/material';

function BackToTop() {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 800,
    })

    const scrollToTop = React.useCallback(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [])

    return (
        <Zoom in={trigger}>
            <div className="back-to-top">
                <Fab aria-label="add" color="secondary" onClick={scrollToTop} size="small">
                    <ExpandLessIcon />
                </Fab>
            </div>
        </Zoom>
    );
}

export default BackToTop;