import * as React from 'react';
import { Link } from "react-router-dom";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';

function DesignsImage(props) {
    const imgUrl = "/img/" + props.details.image;
    const card = (
        <React.Fragment>
            <CardMedia
                sx={{ height: 200 }}
                image={imgUrl}
                title={props.details.title}
            />
            <CardActions>
                <Button component={Link} key={props.details.id} size="small" sx={{ml:'auto'}} to={"/design/" + props.details.url_key}>
                    {props.details.title} <ArrowRightAltIcon />
                </Button>
            </CardActions>
        </React.Fragment>
    );

    return (
        <>
            <Box sx={{ minWidth: 275 }}>
                <Card variant="outlined">{card}</Card>
            </Box>
        </>
    );
}

export default DesignsImage;