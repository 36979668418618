import React, { useEffect } from "react";
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Container } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';

import AlertDialogSlide from "../Components/AlertDialogSlide";
import BasicBreadcrumbs from "../Components/BasicBreadcrumbs";
import LoadingBackdrop from "../Components/LoadingBackdrop";
import MetaSEO from "../Components/MetaSEO";
import useContactSubmit from '../Hooks/useContactSubmit';

function Contact() {
    const docTitle = "Contact - Pug Dogz Designs";
    const docOGUrl = "http://www.pugdogzdesigns.com/contact";
    const { response, submit } = useContactSubmit();
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const breadcrumbs = [{name:"Contact Us", link:""}];

    const { 
        register, handleSubmit, 
        formState: { errors } 
    } = useForm({mode: "onBlur"});

    const handleRegistration = (data) => {
        setOpenBackdrop(true);
        submit("", data);
    };
    
    const handleError = (errors) => {};

    const registerOptions = {
        contactName: { 
            required: "Name is required" 
        },
        contactEmail: { 
            required: "Email is required",
            pattern: {
                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: 'Invalid email address'
            }
        },
        contactMessage: { 
            required: "Message is required" 
        }
    };

    useEffect(() => {
        if (response) {
            setOpenBackdrop(false);
            setOpenAlert(true);
        }
    }, [response]);

    return (
        <Container maxWidth="md" sx={{ pb:2 }}>
            <MetaSEO docTitle={docTitle} docOGUrl={docOGUrl} />
            <BasicBreadcrumbs crumbs={breadcrumbs} />

            <form noValidate onSubmit={handleSubmit(handleRegistration, handleError)}>
            <Box sx={{ flexGrow: 1, margin: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl sx={{width:1}}>
                            <TextField 
                                defaultValue=""
                                error={errors.contactName ? true : false}
                                fullWidth
                                id="contact-name"
                                label="Name"
                                name="contactName"
                                required
                                {...register('contactName', registerOptions.contactName) }
                            />
                            <Typography variant="caption" color="error">
                                {errors.contactName?.message}
                            </Typography>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl sx={{width:1}}>
                            <TextField
                                defaultValue=""
                                error={errors.contactEmail ? true : false}
                                fullWidth
                                id="contact-email"
                                label="Email"
                                name="contactEmail"
                                required
                                {...register('contactEmail', registerOptions.contactEmail) }
                            />
                            <Typography variant="caption" color="error">
                                {errors.contactEmail?.message}
                            </Typography>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl sx={{width:1}}>
                            <TextField
                                defaultValue=""
                                error={errors.contactMessage ? true : false}
                                fullWidth
                                id="contact-message"
                                label="Message"
                                multiline
                                maxRows={50}
                                name="contactMessage"
                                required
                                {...register('contactMessage', registerOptions.contactMessage) }
                            />
                            <Typography variant="caption" color="error">
                                {errors.contactMessage?.message}
                            </Typography>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained">Submit</Button>
                    </Grid>
                </Grid>
            </Box>
            <LoadingBackdrop open={openBackdrop} setOpenBackdrop={setOpenBackdrop} />
            <AlertDialogSlide open={openAlert} setOpenAlert={setOpenAlert} textTitle={response ? response.alertTitle : ""} textContent={response ? response.alertMessage : ""} />
            </form>
        </Container>
    );
}

export default Contact;