function Logo(props) {
    let logoImage = "/img/logo-white.png";

    if(props.type === "black-text") {
        logoImage = "/img/logo.png";
    } else if(props.type === 'mobile-white') {
        logoImage = "/img/logo-mobile-text-white.png";
    }

    return <img src={logoImage} alt="Pug Dogz logo" title="Pug Dogz Designs" className="logo" />;
}

export default Logo;