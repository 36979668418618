import ComputerIcon from '@mui/icons-material/Computer';
import DesignServicesIcon from '@mui/icons-material/DesignServices';

import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import MetaSEO from "../Components/MetaSEO";
import BasicBreadcrumbs from "../Components/BasicBreadcrumbs";

function Services() {
    const docTitle = "Services - Pug Dogz Designs";
    const docOGUrl = "http://www.pugdogzdesigns.com/services";
    const breadcrumbs = [{name:"Services", link:""}];

    return(
        <Container maxWidth="md" sx={{ pb: 2 }}>
            <MetaSEO docTitle={docTitle} docOGUrl={docOGUrl} />
            <BasicBreadcrumbs crumbs={breadcrumbs} />

            <Box>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <DesignServicesIcon color="secondary" fontSize="large" />
                </Stack>
                <Typography sx={{mb:2, textAlign:"center"}} variant="h1">
                    T-shirt Design
                </Typography>
                <Typography sx={{pb:2}}>
                    At Pug Dogz Designs, we believe that style is a form of self-expression. Our passion lies in creating unique, hand-drawn designs that speak to your individuality. Whether you're into bold statements, subtle elegance, or quirky humor, we have something special just for you.
                </Typography>
                <Typography sx={{pb:4}}>
                    Every design starts with a blank canvas and a burst of creativity. Each design is infused with personality and charm. We embrace the imperfections that make each piece truly one-of-a-kind.
                </Typography>

                <Divider sx={{mb:4}} />

                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <ComputerIcon color="secondary" fontSize="large" />
                </Stack>
                <Typography sx={{mb:2, textAlign:"center"}} variant="h1">
                    Web Development
                </Typography>
                <Typography sx={{pb:2}}>
                    At Pug Dogz Designs, we specialize in crafting cutting-edge single-page applications (SPAs) that redefine user interactions. We bring your vision to life with seamless, responsive, and highly performant web solutions.
                </Typography>
                <Typography sx={{pb:2}}>
                    Tailored to your specific needs, we design and develop SPAs that captivate your audience. Our solutions seamlessly integrate with your business logic, creating a cohesive and enjoyable user experience.
                </Typography>
            </Box>
        </Container>
    );
}

export default Services;